
import { defineComponent, ref, toRef } from "vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { hideModal } from "@/core/helpers/dom"
import * as Yup from "yup"

import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import type { ElForm } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "new-box-modal",
  components: {},
  props: [
    'add_box'
  ],
  setup(props) {
    const formRef = ref<FormInstance>()
    const store = useStore()
    const newBoxModalRef = ref<null | HTMLElement>(null)
    const times = ref(1)

    const onSubmit = (formEl: FormInstance | undefined) => {
      props.add_box(times.value)
      times.value = 1
      hideModal(newBoxModalRef.value)
    }

    const onCancel = () => {
      hideModal(newBoxModalRef.value);
    }

    return {
      newBoxModalRef,
      onSubmit,
      formRef,
      onCancel,
      times
    }
  },
})
