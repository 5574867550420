
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import NewBoxModal from "@/components/modals/forms/NewBoxModal.vue"
import { BoxInfo } from "@/store/modules/BoxInfoModule"

export default defineComponent({
  components: {
    NewBoxModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("箱子列表", ["套系管理;box_infos", route.params.id.toString()])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])

    const formData = ref({
      name: "",
      contact_mobile: "",
      enabled: "",
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    const query = () => {
      store
        .dispatch(Actions.GET_BOXES, {
          box_info_id: route.params.id,
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentBoxes
          page.value.totalResult = store.getters.currentBoxCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    };

    query()

    const box_info = ref({} as BoxInfo)

    store
      .dispatch(Actions.GET_BOX_INFO, route.params.id)
      .then(() => {
        box_info.value = store.getters.currentBoxInfo
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      });

    const submitEvent = () => {
      query()
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.name = ''
    }

    const add_box = (times) => {
      if (times <= 0) {
        return
      }

      store
        .dispatch(Actions.CREATE_BOXES, {
          box_info_id: route.params.id,
          times: times
        })
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const current_role = ref('')

    if (store.getters.currentUser) {
      current_role.value = store.getters.currentUser.role
    }

    const toggleStatus = (box) => {
      store
        .dispatch(Actions.UPDATE_BOX_ENABLED, {
          box_info_id: route.params.id,
          id: box.id,
          enabled: !box.enabled
        })
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    // const updateBoxStatus = (box) => {
    //   if (times <= 0) {
    //     return
    //   }

    //   store
    //     .dispatch(Actions.CREATE_BOXES, {
    //       box_info_id: route.params.id,
    //       times: times
    //     })
    //     .then(() => {
    //       query()
    //     })
    //     .catch(() => {
    //       // const [error] = Object.keys(store.getters.getErrors);
    //       // Swal.fire({
    //       //   text: store.getters.getErrors[error],
    //       //   icon: "error",
    //       //   buttonsStyling: false,
    //       //   confirmButtonText: "Try again!",
    //       //   customClass: {
    //       //     confirmButton: "btn fw-bold btn-light-danger",
    //       //   },
    //       // });
    //     })
    // }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onCancel,
      route,
      add_box,
      current_role,
      box_info,
      toggleStatus
    }
  },
})
